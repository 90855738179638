import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Modal from '../components/modal'

import IndexSEOImage from '../images/wood-roof-construction.jpg'

import gc01 from '../images/portfolio/gc/gc-01.jpg'
import gc02 from '../images/portfolio/gc/gc-02.jpg'
import gc03 from '../images/portfolio/gc/gc-03.jpg'
import gc04 from '../images/portfolio/gc/gc-04.jpg'
import gc05 from '../images/portfolio/gc/gc-05.jpg'
import gc06 from '../images/portfolio/gc/gc-06.jpg'
import gc07 from '../images/portfolio/gc/gc-07.jpg'
import gc08 from '../images/portfolio/gc/gc-08.jpg'
import gc09 from '../images/portfolio/gc/gc-09.jpg'

import cm01 from '../images/portfolio/cm/cm-01.jpg'
import cm02 from '../images/portfolio/cm/cm-02.jpg'
import cm03 from '../images/portfolio/cm/cm-03.jpg'
import cm04 from '../images/portfolio/cm/cm-04.jpg'
import cm05 from '../images/portfolio/cm/cm-05.jpg'
import cm06 from '../images/portfolio/cm/cm-06.jpg'
import cm07 from '../images/portfolio/cm/cm-07.jpg'
import cm08 from '../images/portfolio/cm/cm-08.jpg'
import cm09 from '../images/portfolio/cm/cm-09.jpg'

import sw01 from '../images/portfolio/sw/sw-01.jpg'
import sw02 from '../images/portfolio/sw/sw-02.jpg'
import sw03 from '../images/portfolio/sw/sw-03.jpg'
import sw04 from '../images/portfolio/sw/sw-04.jpg'
import sw05 from '../images/portfolio/sw/sw-05.jpg'
import sw06 from '../images/portfolio/sw/sw-06.jpg'
import sw07 from '../images/portfolio/sw/sw-07.jpg'
import sw08 from '../images/portfolio/sw/sw-08.jpg'
import sw09 from '../images/portfolio/sw/sw-09.jpg'

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: false }
        this.state = { setShow: false }

        this.changeItems = this.changeItems.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.expandModal = this.expandModal.bind(this)
        this.collapseModal = this.collapseModal.bind(this)
    }
    
    changeItems(e,filter) {
        e.preventDefault()
        var portfolioItems = document.getElementsByClassName('grid-item')
        var buttons = document.getElementsByClassName('btn')
        for ( var x = 0; x < buttons.length; x++ ) {
            buttons[x].classList.remove('selected')
        }
        if ( filter !== '*' ) {
            var theButton = document.querySelector('button.'+filter)
            theButton.classList.add('selected')
        } else {
            var showAll = document.getElementsByClassName('all')
            showAll[0].classList.add('selected')
        }
        for ( var i = 0; i < portfolioItems.length; i++ ) {
            var item = portfolioItems[i]
            if ( filter !== '*' ) {
                if ( item.classList.contains(filter) ) {
                    item.classList.remove('isotope-hidden')
                } else {
                    item.classList.add('isotope-hidden')
                }    
            } else {
                item.classList.remove('isotope-hidden')
            }
        }
    }
    
    handleClick(title,image) {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }))
        if ( this.isToggleOn ) {
            this.expandModal()
            return <Modal title={title} image={image} setShow={() => this.expandModal} handleClose={() => this.collapseModal} />
        }
    }
    
    expandModal() {
        this.setState({ setShow: true })
    }
    
    collapseModal() {
        this.setState({ setShow: false })
    }
    
    render() {
        return (
            <>
            <Layout page='our-work'>
                <SEO title='Our Work' description={`Ochoa Enterprises offers planning, coordination, budgeting and overseeing every aspect of your residential or commercial build, construction management, and specialty work.`} image={IndexSEOImage} keywords={[``]} />
                <Banner title='Our Work' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
                <section>
                    <Container>
                        <Row>
                            <Col xs='12' sm='12' md='12' lg='12'>
                                <div className='spacer20'></div>

                                <h2>Contracting &amp; Construction Management</h2>

                                <p>Ochoa Enterprises offers planning, coordination, budgeting and overseeing every aspect of your residential or commercial build, construction management of a project, and specialty work upon request.</p>

                                <div className='spacer20'></div>

                                <h2>View Our Work</h2>

                                <div className='button-group filter-button-group'>
                                    <button className='btn btn-secondary all selected' data-filter='*' onClick={(e) => this.changeItems(e,'*')}>Show All</button>
                                    <button className='btn btn-secondary gc' data-filter='.gc' onClick={(e) => this.changeItems(e,'gc')}>General Contractor</button>
                                    <button className='btn btn-secondary cm' data-filter='.cm' onClick={(e) => this.changeItems(e,'cm')}>Contract Management</button>
                                    <button className='btn btn-secondary sw' data-filter='.sw' onClick={(e) => this.changeItems(e,'sw')}>Specialty Work</button>
                                </div>

                                <div id='portfolio' className='grid' data-isotope='{ "filter": "*", "itemSelector": ".grid-item", "layoutMode": "fitRows": { "gutter": "10"} }'>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc01} alt='Ground-up medical facility in Livingston, NJ' />
                                        <p>Ground-up medical facility in Livingston, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc02} alt='Site work for new ground-up building in Livingston, NJ' />
                                        <p>Site work for new ground-up building in Livingston, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc03} alt='Custom built addition to existing structure in Essex Fells, NJ' />
                                        <p>Custom built addition to existing structure in Essex Fells, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc04} alt='Ground-up custom home in Harding, NJ' />
                                        <p>Ground-up custom home in Harding, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc05} alt='Ground-up custom pool house in Alpine, NJ' />
                                        <p>Ground-up custom pool house in Alpine, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc06} alt='Tenant fit-out in Madison, NJ' />
                                        <p>Tenant fit-out in Madison, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc07} alt='Ground-up custom home in Far Hills, NJ' />
                                        <p>Ground-up custom home in Far Hills, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc08} alt='Ground-up custom home in Bedminster, NJ' />
                                        <p>Ground-up custom home in Bedminster, NJ</p>
                                    </div>
                                    <div className='grid-item gc'>
                                        <img className='img-responsive' src={gc09} alt='Ground-up custom pool house in Basking Ridge, NJ' />
                                        <p>Ground-up custom pool house in Basking Ridge, NJ</p>
                                    </div>

                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm01} alt='Site improvements to existing commercial property in Florham Park, NJ' />
                                        <p>Site improvements to existing commercial property in Florham Park, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm02} alt='Commercial tenant fit-out in Newark, NJ' />
                                        <p>Commercial tenant fit-out in Newark, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm03} alt='Mechanical upgrades to existing heating/cooling system in Secaucus, NJ' />
                                        <p>Mechanical upgrades to existing heating/cooling system in Secaucus, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm04} alt='Roof top unit upgrade to distribution facility in Secaucus, NJ' />
                                        <p>Roof top unit upgrade to distribution facility in Secaucus, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm05} alt='Commercial tenant fit-out in Florham Park, NJ' />
                                        <p>Commercial tenant fit-out in Florham Park, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm06} alt='Mechanical upgrades to existing facility in Newark, NJ' />
                                        <p>Mechanical upgrades to existing facility in Newark, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm07} alt='Ground-up commercial retail in Brick, NJ' />
                                        <p>Ground-up commercial retail in Brick, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm08} alt='Mechanical upgrades to existing school in Jersey City, NJ' />
                                        <p>Mechanical upgrades to existing school in Jersey City, NJ</p>
                                    </div>
                                    <div className='grid-item cm'>
                                        <img className='img-responsive' src={cm09} alt='Ground-up commercial site work in Livingston, NJ' />
                                        <p>Ground-up commercial site work in Livingston, NJ</p>
                                    </div>

                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw01} alt='Custom copper work in Far Hills, NJ' />
                                        <p>Custom copper work in Far Hills, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw02} alt='Custom copper dormer in Alpine, NJ' />
                                        <p>Custom copper dormer in Alpine, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw03} alt='Custom Copper over main entrance in Metuchen, NJ' />
                                        <p>Custom Copper over main entrance in Metuchen, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw04} alt='Custom copper work for custom backyard in Mendham, NJ' />
                                        <p>Custom copper work for custom backyard in Mendham, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw05} alt='Custom cedar shake roof in Bedminster, NJ' />
                                        <p>Custom cedar shake roof in Bedminster, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw06} alt='Custom roof tile in new ground-up structure in Lebanon, NJ' />
                                        <p>Custom roof tile in new ground-up structure in Lebanon, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw07} alt='Custom copper vent dormer in Bedminster, NJ' />
                                        <p>Custom copper vent dormer in Bedminster, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw08} alt='Copper valley and slate roof repairs on Summit, NJ' />
                                        <p>Copper valley and slate roof repairs on Summit, NJ</p>
                                    </div>
                                    <div className='grid-item sw'>
                                        <img className='img-responsive' src={sw09} alt='Custom copper chimney in Bedminster, NJ' />
                                        <p>Custom copper chimney in Bedminster, NJ</p>
                                    </div>
                                </div>
                                <div className='spacer50'></div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
            </>
        )
    }
}

export default Portfolio