import React from "react"
import PropTypes from "prop-types"
import { Modal, Button } from "react-bootstrap"

import defaultImg from '../images/logo.png'

const ImageModal = ({ title, image, setShow, handleClose }) => (
    <Modal.Dialog show={setShow} onHide={handleClose} backdrop='static' size='lg' scrollable>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <img className='img-responsive' src={image} alt={title} />
        </Modal.Body>

        <Modal.Footer>
            <Button variant='primary'>Close</Button>
        </Modal.Footer>
    </Modal.Dialog>
)

ImageModal.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
}

ImageModal.defaultProps = {
    title: ``,
    image: `${defaultImg}`
}

export default ImageModal
